import { useContext, useEffect, useRef, useState } from 'react';

import { MeContext } from 'auth/AuthContextProvider';
import { HomeSidebar } from 'components/HomeSidebar';
import { Layout } from 'components/Layout';
import { CustomLayoutBouygue } from 'components/CustomLayoutBouygue';

import {
  Center,
  Text,
  Stack,
  VStack,
  RadioGroup,
  Radio,
  Spinner,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { Input } from 'components/Input';
import { Panel } from 'components/Panel';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { CitySearch } from 'components/CitySearch';

export const PtzPage = () => {
  const [counter, setCounter] = useState<number>(0);
  const counterRef = useRef<number>(0);

  const [usage, setUsage] = useState<string>('RES_PRINCIPALE');
  const [primoAccedant, setPrimoAccedant] = useState<number>(1);
  const [typeLogement, setTypeLogement] = useState<string>('LOGEMENT_NEUF');

  const [logementNeufSousType, setLogementNeufSousType] = useState<string>('COLLECTIF');
  const [logementAncienSousType, setLogementAncienSousType] = useState<string>('TRAVAUX');

  const [cp, setCp] = useState<string>('35000');
  const [zone, setZone] = useState<string>('B1');
  const [resetZone, SetResetZone] = useState<number>(0);
  const [montant, setMontant] = useState<string>('150000');
  const [nbOccupant, setNbOccupant] = useState<number>(2);
  const [apport, setApport] = useState<string>('20000');
  const [rfr, setRfr] = useState<string>('20000');

  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [resultEligibilite, setResultEligibilite] = useState<boolean>(false);
  const [resultatAmortissement, setResultatAmortissement] = useState<number>(0);
  const [resultatDiffere, setResultatDiffere] = useState<number>(0);
  const [resultatDuree, setResultatDuree] = useState<number>(0);
  const [resultatMontant, setResultatMontant] = useState<number>(0);

  async function Callcalculatrice() {
    if (usage !== 'RES_PRINCIPALE') {
      setResultEligibilite(false);
      return;
    }
    if (primoAccedant !== 1) {
      setResultEligibilite(false);
      return;
    }
    setResultLoading(true);

    let type_projet: string = typeLogement;
    let cout_operation: number = Number(montant) * 100;
    let travaux = 0;
    if (typeLogement === 'LOGEMENT_ANCIEN' && logementAncienSousType === 'SOCIAL') {
      type_projet = 'RACHAT_LOGEMENT_SOCIAL';
    }
    if (typeLogement === 'LOGEMENT_ANCIEN' && logementAncienSousType === 'TRAVAUX') {
      const pourcent: number = cout_operation * 0.26;
      travaux = pourcent;
      cout_operation -= pourcent;
    }
    await fetch('https://www.monemprunt.com/api/calculette/ptz', {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        usage: 'RES_PRINCIPALE',
        primo_accedant: primoAccedant === 1,
        zone: zone,
        nombre_personnes_foyer: nbOccupant,
        cout_operation: cout_operation,
        apport: Number(apport) * 100,
        revenu_fiscal_reference: Number(rfr) * 100,
        type_projet: type_projet,
        type_neuf: typeLogement === 'LOGEMENT_NEUF' ? logementNeufSousType : '',
        frais_supplementaires: 0,
        travaux: travaux,
      }),
    })
      .then((res) =>
        res.json().then((result) => {
          console.log(result);
          setResultEligibilite(result.eligible_ptz ? result.eligible_ptz : false);
          if (result.eligible_ptz == true) {
            setResultatAmortissement(result.ptz.amortissement);
            setResultatDiffere(result.ptz.differe);
            setResultatDuree(result.ptz.duree);
            setResultatMontant(result.ptz.montant);
          }
        }),
      )
      .catch((err) => console.log(err))
      .finally(() => setResultLoading(false));
  }

  async function launcherCallCalculatrice(actualCounter: number) {
    await new Promise((f) => setTimeout(f, 400));
    if (counterRef.current === actualCounter) Callcalculatrice();
  }

  useEffect(() => {
    setResultLoading(true);
    setResultEligibilite(false);

    launcherCallCalculatrice(counter);
    counterRef.current = counter;
    setCounter(counter + 1);
  }, [
    usage,
    primoAccedant,
    typeLogement,
    logementNeufSousType,
    logementAncienSousType,
    cp,
    montant,
    nbOccupant,
    rfr,
    apport,
    zone,
  ]);

  return (
    <>
      <Center width={'100%'}>
        <Text pl={'20px'} width={'100%'} as="h3" fontSize={'25px'} fontWeight="semibold" my={4}>
          Calculatrice d'éligibilité PTZ
        </Text>
      </Center>

      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 8, lg: 8 }}>
        <VStack
          as="fieldset"
          align={'left'}
          flex={1}
          spacing={4}
          width={{ base: '100%', lg: '50%' }}
        >
          <Panel p={'30px'} w="100%">
            <Text>Type de résidence</Text>
            <RadioGroup value={usage}>
              <Stack spacing={5} direction="row">
                <Radio
                  colorScheme="teal"
                  value={'RES_PRINCIPALE'}
                  onClick={() => setUsage('RES_PRINCIPALE')}
                >
                  Principale
                </Radio>
                <Radio
                  colorScheme="teal"
                  value={'RES_SECONDAIRE'}
                  onClick={() => setUsage('RES_SECONDAIRE')}
                >
                  Secondaire
                </Radio>
                <Radio colorScheme="teal" value={'LOCATIF'} onClick={() => setUsage('LOCATIF')}>
                  Locative
                </Radio>
              </Stack>
            </RadioGroup>
          </Panel>

          {usage === 'RES_PRINCIPALE' && (
            <>
              <Panel p={'30px'} w="100%">
                <Text>Primo Accèdant</Text>
                <RadioGroup value={primoAccedant}>
                  <Stack spacing={5} direction="row">
                    <Radio colorScheme="teal" value={1} onClick={() => setPrimoAccedant(1)}>
                      Oui
                    </Radio>
                    <Radio colorScheme="teal" value={0} onClick={() => setPrimoAccedant(0)}>
                      Non
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Panel>

              {primoAccedant === 1 && (
                <>
                  <Panel p={'30px'} w="100%">
                    <Text as="b">Information de l'emprunteur </Text>
                    <Text>Nombre d'occupants du futur logement </Text>
                    <Slider
                      aria-label="slider-ex-6"
                      min={1}
                      max={6}
                      pt={'20px'}
                      value={nbOccupant}
                      onChange={(val) => setNbOccupant(val)}
                    >
                      <SliderMark ml={'-4px'} pt={'5px'} value={1}>
                        1
                      </SliderMark>
                      <SliderMark ml={'-4px'} pt={'5px'} value={2}>
                        2
                      </SliderMark>
                      <SliderMark ml={'-4px'} pt={'5px'} value={3}>
                        3
                      </SliderMark>
                      <SliderMark ml={'-4px'} pt={'5px'} value={4}>
                        4
                      </SliderMark>
                      <SliderMark ml={'-4px'} pt={'5px'} value={5}>
                        5
                      </SliderMark>
                      <SliderMark ml={'-6px'} pt={'5px'} value={6}>
                        5+
                      </SliderMark>
                      <SliderTrack bg={'lightgray'}>
                        <SliderFilledTrack bg={'teal'} />
                      </SliderTrack>
                      <SliderThumb bg={'teal'}></SliderThumb>
                    </Slider>
                    <Text pt="20px">Revenu Fiscal de référence</Text>
                    <InputGroup>
                      <Input
                        value={rfr}
                        onChange={(e) => setRfr(e.target.value.replace(/\D/g, ''))}
                      ></Input>
                      <InputRightAddon h={'45px'}>Euros</InputRightAddon>
                    </InputGroup>
                    <Text> Montant total du projet </Text>
                    <InputGroup>
                      <Input
                        value={montant}
                        onChange={(e) => setMontant(e.target.value.replace(/\D/g, ''))}
                      ></Input>{' '}
                      <InputRightAddon h={'45px'}>Euros</InputRightAddon>
                    </InputGroup>
                    <Text>Apport personnel</Text>
                    <InputGroup>
                      <Input
                        value={apport}
                        onChange={(e) => setApport(e.target.value.replace(/\D/g, ''))}
                      ></Input>{' '}
                      <InputRightAddon h={'45px'}>Euros</InputRightAddon>
                    </InputGroup>
                  </Panel>
                  <Panel p={'30px'} w="100%">
                    <Text as="b">Information du bien </Text>
                    <Text>Zone PTZ du bien</Text>
                    <Text fontSize={'12px'}>
                      La ville permet de connaitre la zone PTZ, sélectionnez là ou entrer
                      directement la zone cible.
                    </Text>
                    <CitySearch
                      onSelect={(city) => {
                        setZone(city.zone_ptz);
                      }}
                      resetZoneTrigger={resetZone}
                    />
                    <RadioGroup value={zone} mt={'10px'}>
                      <Stack spacing={5} direction="row">
                        <Radio
                          colorScheme="teal"
                          value={'A'}
                          onClick={() => {
                            setZone('A');
                            SetResetZone(resetZone + 1);
                          }}
                        >
                          A
                        </Radio>
                        <Radio
                          colorScheme="teal"
                          value={'A BIS'}
                          onClick={() => {
                            setZone('A BIS');
                            SetResetZone(resetZone + 1);
                          }}
                        >
                          A Bis
                        </Radio>
                        <Radio
                          colorScheme="teal"
                          value={'B1'}
                          onClick={() => {
                            setZone('B1');
                            SetResetZone(resetZone + 1);
                          }}
                        >
                          B1
                        </Radio>
                        <Radio
                          colorScheme="teal"
                          value={'B2'}
                          onClick={() => {
                            setZone('B2');
                            SetResetZone(resetZone + 1);
                          }}
                        >
                          B2
                        </Radio>
                        <Radio
                          colorScheme="teal"
                          value={'C'}
                          onClick={() => {
                            setZone('C');
                            SetResetZone(resetZone + 1);
                          }}
                        >
                          C
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <Text>Projet </Text>
                    <RadioGroup value={typeLogement}>
                      <Stack spacing={5} direction="row">
                        <Radio
                          colorScheme="teal"
                          value={'LOGEMENT_NEUF'}
                          onClick={() => setTypeLogement('LOGEMENT_NEUF')}
                        >
                          Neuf
                        </Radio>
                        <Radio
                          colorScheme="teal"
                          value={'LOGEMENT_ANCIEN'}
                          onClick={() => setTypeLogement('LOGEMENT_ANCIEN')}
                        >
                          Ancien
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    {typeLogement === 'LOGEMENT_NEUF' && (
                      <>
                        <Text>Logement individuel ou collectif ?</Text>
                        <RadioGroup value={logementNeufSousType}>
                          <Stack spacing={5} direction="column">
                            <Radio
                              colorScheme="teal"
                              value={'COLLECTIF'}
                              onClick={() => setLogementNeufSousType('COLLECTIF')}
                            >
                              Collectif
                            </Radio>
                            <Radio
                              colorScheme="teal"
                              value={'INDIVIDUEL'}
                              onClick={() => setLogementNeufSousType('INDIVIDUEL')}
                            >
                              Individuel
                            </Radio>
                            <Radio
                              colorScheme="teal"
                              value={'PSLA'}
                              onClick={() => setLogementNeufSousType('PSLA')}
                            >
                              Logement social (PSLA ou BRS)
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </>
                    )}
                    {typeLogement !== 'LOGEMENT_NEUF' && (
                      <>
                        <Text>Avec travaux ou social ?</Text>
                        <RadioGroup value={logementAncienSousType}>
                          <Stack spacing={5} direction="column">
                            <Radio
                              colorScheme="teal"
                              value={'NOTRAVAUX'}
                              onClick={() => setLogementAncienSousType('NOTRAVAUX')}
                            >
                              Montant de travaux inférieur à 25% du coût total de l’opération
                            </Radio>
                            <Radio
                              colorScheme="teal"
                              value={'TRAVAUX'}
                              onClick={() => setLogementAncienSousType('TRAVAUX')}
                            >
                              Montant de travaux supérieur à 25% du coût total de l’opération
                            </Radio>
                            <Radio
                              colorScheme="teal"
                              value={'SOCIAL'}
                              onClick={() => setLogementAncienSousType('SOCIAL')}
                            >
                              Rachat d’un logement social
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </>
                    )}
                  </Panel>
                </>
              )}
            </>
          )}
        </VStack>
        <VStack flex={1} spacing={4} align={'left'} width={{ base: '100%', lg: '50%' }}>
          <Panel p={'30px'} width={'100%'}>
            <Text as="b" mb="10px">
              Resultat de la simulation :
            </Text>
            {resultLoading && (
              <Box mt={'50px'}>
                <Center>
                  <Spinner size="xl" mb="5px" color="teal" thickness="4px" speed="0.65s"></Spinner>
                </Center>
                <Center>
                  <Text mt={'10px'}>Chargement du resultat</Text>
                </Center>
              </Box>
            )}
            {!resultLoading && resultEligibilite && (
              <>
                <Box mt={'50px'}>
                  <Center>
                    <CheckIcon fontSize={'60px'} color="green.200"></CheckIcon>
                  </Center>
                  <Center>
                    <Text color={'green'} mt={'10px'}>
                      Projet éligible au PTZ
                    </Text>
                  </Center>
                </Box>
                <Text mt={'50px'}>
                  <u>Montant :</u> {resultatMontant / 100} €{' '}
                </Text>
                <Text>
                  <u>Duree total:</u> {resultatDuree / 12} ans
                </Text>
                <Text>
                  <u>Durée Differe :</u> {resultatDiffere / 12} ans
                </Text>
                <Text>
                  <u>Durée Amortissement :</u> {resultatAmortissement / 12} ans
                </Text>
              </>
            )}

            {!resultLoading && !resultEligibilite && (
              <>
                <Box mt={'50px'}>
                  <Center>
                    <CloseIcon fontSize={'60px'} color="red.200"></CloseIcon>
                  </Center>
                  <Center>
                    <Text color={'red'} mt={'10px'}>
                      Projet non éligible au PTZ
                    </Text>
                  </Center>
                </Box>
              </>
            )}
          </Panel>
        </VStack>
      </Stack>
    </>
  );
};

export const CalculatricePtzPageRoute = () => {
  const me = useContext(MeContext);

  return (
    <Layout
      courtier={me.courtier!}
      utilisateur={me.utilisateur!}
      sidebar={<HomeSidebar withKPI={false} withFilter={false} utilisateur={me.utilisateur!} />}
      leftSidebar={true}
    >
      <PtzPage />
    </Layout>
  );
};

export const PublicCalculatricePtzPageRoute = () => {
  return (
    <CustomLayoutBouygue>
      <PtzPage />{' '}
    </CustomLayoutBouygue>
  );
};
